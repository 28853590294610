<template>
	<div class="header">
		<div class="header_l">
			<img src="../../../assets/logonew.png" alt="" />
			<div class="tab_list">
				<div
					v-for="(item, index) in navlist"
					:key="index"
					@click="toPath(item, index)"
					:class="{ active: currentIndex === index }"
				>
					{{ item.name }}
					<span
						class="tab_tag"
						v-if="item.name == '审核管理' && examineTag > 0"
						>{{ examineTag }}</span
					>
				</div>
			</div>
		</div>
		<el-popover placement="top-start" width="50" trigger="hover">
			<div style="text-align: center" class="pointer" @click="logOut">
				退出
			</div>
			<div class="header_r" slot="reference">
				<img src="../../../assets/logo.png" alt="" />
				<span>admin</span>
			</div>
		</el-popover>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { tabTag } from "@/api/data";
export default {
	name: "header",
	data() {
		return {
			currentIndex: 0,
			examineTag: 0,
			navlist: [
				{
					name: "首页",
					router: "/index",
				},
				{
					name: "用户管理",
					router: "/user/userlist",
				},
				{
					name: "资讯管理",
					router: "/message/messagelist",
				},
				{
					name: " 活动管理",
					router: "/event/events",
				},
				{
					name: "推荐管理",
					router: "/recommend/eventbanner",
				},
				{
					name: "审核管理",
					router: "/examine/messageexamine",
				},
				{
					name: "消息管理",
					router: "/information/push",
				},
				{
					name: "权限管理",
					router: "/limitsauthority/personnel",
				},
				{
					name: "运营数据",
					router: "/webdata/pvuv",
				},
			],
		};
	},
	computed: {
		...mapGetters(["user"]),
	},
	created() {
		this.getDataList();
	},
	methods: {
		toPath(item, index) {
			this.currentIndex = index;
			this.$router.push({ path: item.router });
			this.getDataList();
		},
		logOut() {
			store.dispatch("user/logout");
		},
		getDataList() {
			tabTag({})
				.then((res) => {
					if (res.code == 200) {
						this.examineTag = res.data.data.length;
					} else {
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped>
.header {
	width: 100%;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f9f9f9;
	position: fixed;
	top: 0;
	z-index: 1000;
}

.header_l,
.tab_list {
	display: flex;
	align-items: center;
}
.active {
	color: blue;
}
.tab_list {
	margin-left: 50px;
}

.header_l img {
	width: 100px;
	/* height: 50px; */
	margin-left: 20px;
}

.tab_list div {
	font-size: 16px;
	margin-left: 40px;
	display: flex;
}

.header_r {
	display: flex;
	align-items: center;
	margin-right: 30px;
	font-size: 18px;
}

.header_r span {
	font-size: 18px;
}

.header_r img {
	width: 40px;
	height: 40px;
	border-radius: 50px;
	margin-right: 15px;
}

.tab_tag {
	background-color: red;
	padding-left: 5px;
	padding-right: 5px;
	/* padding-top: 2px; */
	height: 16px;
	margin-top: -6px;
	margin-left: 4px;
	border-radius: 4px;
	color: white;
	display: flex;
	align-content: center;
}
</style>
